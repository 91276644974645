export const fallbackLng = "en";
export const languages = [fallbackLng, "ja", "zh", "de"];
export const defaultNS = "index";
export const localeLocaleStorageKey = "locale";

export enum Lng {
  "zh" = "zh",
  "en" = "en",
  "ja" = "ja",
  "de" = "de",
}

export const LngList = [Lng.zh, Lng.en, Lng.ja, Lng.de];

export const LngMap: Record<string, string> = {
  en: "English",
  ja: "日本語",
  zh: "简体中文",
  de: "Deutsch",
};

export function getOptions(lng = fallbackLng, ns = defaultNS) {
  return {
    // debug: true,
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns: [defaultNS, ns],
  };
}
